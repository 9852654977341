import '../css'
import Sortable from 'sortablejs'
import { Stimulus, Controller } from '#/application/js/stimulus'
import { submitForm } from '#/application/js/utils'

Stimulus.register('tasks', class extends Controller {
  connect() {
    Sortable.create(this.element, {
      group: 'tasks',
      handle: '.fa-up-down',
      direction: 'vertical',
      onUpdate: () => {
        const form = document.querySelector('form#reorder-tasks') // Workaround for nested forms
        this.element.querySelectorAll('input[name="task_ids[]"]').forEach(input => form.append(input))
        submitForm(form)
      }
    })
  }
})

// Update task when checked/unchecked
addEventListener('change', ({ target }) => {
  if (target.matches('input[name="task[complete]"]')) {
    submitForm(target, '_top')
  }
})
