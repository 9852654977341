const submitForm = function(el, target=null) {
  let form = el.closest('form')
  if (!form) return

  if (target) {
    form.dataset.turboFrame = target
  }

  form.querySelector('input[type="submit"]').click()
}

export { submitForm }
