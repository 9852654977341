import { submitForm } from './utils'

// Workaround for data-turbo-method links within forms
addEventListener('click', e => {
  let el = e.target.closest('a[data-method]')

  if (el) {
    e.preventDefault()

    let form = document.querySelector(el.dataset.target)
    form.action = el.href
    form.method = el.dataset.method
    submitForm(form)
  }
})

// Auto-focus
const autofocus = function() {
  let el = document.querySelector('input[data-autofocus]')
  if (el) el.focus()
}

addEventListener('turbo:load', autofocus)
addEventListener('turbo:frame-load', autofocus)

// Auto-select
addEventListener('focusin', ({ target }) => {
  if (target.matches('input[data-autoselect]')) {
    target.select()
  }
})

// Submit on input blur
addEventListener('focusout', ({ target }) => {
  if (target.matches('input[data-submit-on-blur]')) {
    submitForm(target)
  }
})

// Reset on input esc
addEventListener('keydown', e => {
  if (e.target.matches('input[data-autoselect]') && e.key == 'Escape') {
    history.back()
  }
})
