import '@hotwired/turbo'

import { config, library, dom } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faXmark, faListUl, faUpDown } from '@fortawesome/free-solid-svg-icons'
config.mutateApproach = 'sync'
library.add(faCheck, faXmark, faListUl, faUpDown)
dom.watch()

import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/modal'
import 'bootstrap/dist/css/bootstrap.css'

import '#/application/js'
import '#/lists/js'
import '#/tasks/js'
import '#/users/js'
