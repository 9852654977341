import '../css'
import Sortable from 'sortablejs'
import { Stimulus, Controller } from '#/application/js/stimulus'
import { submitForm } from '#/application/js/utils'

Stimulus.register('lists', class extends Controller {
  connect() {
    Sortable.create(this.element, {
      group: 'lists',
      handle: '.fa-up-down',
      direction: 'vertical',
      onUpdate: () => submitForm(this.element)
    })
  }
})
